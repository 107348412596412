import { MESSAGES, FILE_SOURCE } from '@constants'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import chunk from 'lodash/chunk'
import kebabCase from 'lodash/kebabCase'
import split from 'lodash/split'
import first from 'lodash/first'

export default {
  name: 'globalMixin',

  data() {
    return {
      loadingObj: {
        addressField: false,
        createAccount: false,
        requestAccess: false,
        forgotPassword: false,
        login: false,
        register: false,
        resetPassword: false,
        resendVerifyEmail: false,
        getLocations: false,
        saveDraft: false,
        save: false,
        publish: false,
        sendMessage: false,
        changeEmail: false,
        changePassword: false,
        addEmail: false,
        addPhone: false,
        resend: false,
        verify: false,
        inviteUser: false,
        updateUser: false,
        getAccount: false,
        removeAccount: false,
        submit: false,
        createLocation: false,
        sendRequest: false,
        acceptRequest: false,
        getWhat3Words: false,
        onBoardingBtn: false,
        confirmPayment: false,
        spinner: false
      },
      currentTheme: 'light'
    }
  },

  mounted() {
    this.currentTheme = localStorage.getItem('color-theme')
    this.emitter.on('checkIsSubscribed', this.checkPlanCancelled)
    this.emitter.on('setCurrentTheme', this.setCurrentTheme)
  },
  beforeUnmount() {
    this.emitter.off('checkIsSubscribed', this.checkPlanCancelled)
    this.emitter.off('setCurrentTheme', this.setCurrentTheme)
  },

  computed: {
    breakpoint() {
      const breakpoint = {
        xs: this.$vuetify.display.xs,
        sm: this.$vuetify.display.sm,
        md: this.$vuetify.display.md,
        lg: this.$vuetify.display.lg,
        xl: this.$vuetify.display.xl,

        xsOnly: this.$vuetify.display.xsOnly,
        smOnly: this.$vuetify.display.smOnly,
        mdOnly: this.$vuetify.display.mdOnly,
        lgOnly: this.$vuetify.display.lgOnly,
        xlOnly: this.$vuetify.display.xlOnly,

        smAndUp: this.$vuetify.display.smAndUp,
        mdAndUp: this.$vuetify.display.mdAndUp,
        lgAndUp: this.$vuetify.display.lgAndUp,

        smAndDown: this.$vuetify.display.smAndDown,
        mdAndDown: this.$vuetify.display.mdAndDown,
        lgAndDown: true
      }

      return breakpoint
    },
    $loading: {
      get() {
        return this.loadingObj
      },
      set() {}
    }
  },

  methods: {
    checkPlanCancelled(accounts) {
      const accountData = accounts.find((account) => account._id == this.$account?._id)
      const plan = accountData?.plans
      if (this.$route.name != 'billing.view' && this.$account?.plans?.isCanceled) {
        this.$account.plans.isCanceled = plan.isCanceled
        this.$next({ name: 'billing.view' })
      }
    },
    $loginDialog(value, options) {
      if (!['login', 'register'].includes(this.$route.name)) {
        return new Promise((resolve) => {
          this.emitter.emit('auth-dialog', value, options, resolve)
        })
      }
    },
    async $confirm(title, subtitle, options, replaceValue = null) {
      let message = MESSAGES[title] || title
      message = message.replace('{replaceValue}', replaceValue)

      return new Promise((resolve) => {
        this.emitter.emit('confirm', {
          title: message,
          subtitle,
          callback: resolve,
          ...options
        })
      })
    },
    $scrollToTop() {
      window.scrollTo(0, 0)
    },
    getMediaKey(fileSource, contentType, accountId, _id) {
      const fileName = `${Date.now()}${uuidv4().split('-')[0]}`
      const postLink = `original/${fileName}`
      switch (fileSource) {
        case FILE_SOURCE.LOCATION: {
          if (contentType.startsWith('image/')) {
            return `${accountId}/${fileSource}/${_id}/${postLink}`
          } else if (contentType.startsWith('video/')) {
            return `${accountId}/${fileSource}/${_id}/video/${postLink}`
          } else if (contentType.startsWith('audio/')) {
            return `${accountId}/${fileSource}/${_id}/audio/${postLink}`
          } else if (contentType.startsWith('application/') || contentType.startsWith('text/')) {
            return `${accountId}/${fileSource}/${_id}/document/${postLink}`
          } else {
            return `${accountId}/${fileSource}/${_id}/other/${postLink}`
          }
        }
        case FILE_SOURCE.COLLECTION:
          return `${accountId}/${fileSource}/${_id}/${postLink}`
        default:
          return `${fileSource}/${fileName}/original`
      }
    },
    capitalize(word) {
      return word?.charAt(0)?.toUpperCase() + word?.slice(1)
    },
    getTimestamp(length = 1) {
      const uuid = uuidv4()
      return (Date.now() + length + uuid.split('-')[0]).toString()
    },
    hasForbidden(key, dependOnLevel, userRole, yourRole) {
      if (!(this.$permissions || []).includes(key)) return true

      if (!userRole || !yourRole) return false

      if (yourRole.priority === userRole.priority) return true

      if (!dependOnLevel) return false

      return yourRole.priority >= userRole.priority
    },

    getLocationAddress(location) {
      let addressData = ''
      addressData = location?.address?.google?.ward
        ? location?.address?.google?.ward
        : location?.address?.w3w?.nearestPlace
          ? location?.address?.w3w?.nearestPlace
          : ''

      return addressData
    },

    formateDate(date) {
      return dayjs(date).format('DD MMMM YYYY')
    },

    mailTo(email) {
      window.open(`mailto:${email}`)
    },

    generateRandomId(length = 10) {
      const uuid = uuidv4()
      return uuid.replace(/-/g, '').substring(0, length)
    },

    $copy(text, message = 'URL copied to clipboard') {
      navigator.clipboard.writeText(text).then(
        () => {
          this.emitter.emit('success', message)
        },
        (error) => {
          this.emitter.emit('error', 'Could not copy text: ' + error)
        }
      )
    },
    setCurrentTheme(name) {
      this.currentTheme = name
    },

    getChunksOfArrays(dataOfArray = [], sizeOfChunk = 50) {
      const chunkSize = Math.ceil(dataOfArray.length / sizeOfChunk) // Ensures approximately 50 chunks
      const chunks = chunk(dataOfArray, sizeOfChunk) || []
      return chunks
    },

    convertDate(dateString) {
      const [year, month, day, hour, minute, second] = dateString.split(/[- :]/)
      // Months are zero-based in JavaScript Date objects, so we subtract 1 from the month
      const dateObject = new Date(Date.UTC(year, month - 1, day, hour, minute, second))
      return dateObject
    },

    convertToKebabCase(key) {
      return kebabCase(key)
    }
  }
}
