<template>
  <v-row class="text-caption text-lg-subtitle-2 pa-4 text-center">
    <v-col cols="12" md="12" class="py-2 py-sm-0">
      <span>Copyright @{{ calYear }} - DBMA Ltd - All rights reserved</span>
      <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
      <span>
        <a
          @click="$openBlankWindow({ name: 'policies' })"
          target="_blank"
          class="link cursor-pointer"
          dark
        >
          Privacy
        </a>
        <span>&nbsp;·&nbsp;</span>

        <a
          @click="$openBlankWindow({ name: 'terms' })"
          target="_blank"
          class="link cursor-pointer"
          dark
        >
          Terms
        </a>
        <span>&nbsp;·&nbsp;</span>

        <a class="link cursor-pointer" target="_blank" href="https://superscout.ai/contact/" dark>
          Contact us
        </a>
      </span>
      <!-- <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span> -->
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TheFooter',
  computed: {
    calYear() {
      return new Date().getFullYear()
    }
  }
}
</script>
