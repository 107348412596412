<template>
  <header v-if="hideShowHeader" :key="hideShowHeader" class="position-sticky top-0 z-20">
    <div class="border-b border-gray-200 bg-white">
      <div class="mx-auto px-4 sm:px-6 lg:px-8 py-0.5">
        <div
          :class="[
            $route?.name == 'search.view' && breakpoint?.smAndDown && 'justify-start',
            ((breakpoint?.smAndDown && $route?.name != 'search.view') || breakpoint?.mdAndUp) &&
              'justify-between'
          ]"
          class="flex h-16"
        >
          <div
            class="flex text-nowrap items-center md:w-2/3"
            :class="[
              breakpoint.smAndDown ? breakpoint.xs && 'w-2/5' : 'w-3/5',
              breakpoint.smAndDown && $route.name == 'search.view' && 'hidden'
            ]"
          >
            <div
              v-if="($route?.name != 'search.view' && breakpoint?.smAndDown) || breakpoint?.mdAndUp"
              class="flex items-center logo-img h-full"
              :class="$account?.media?.key ? 'justify-start' : 'md:w-2/5 xl:w-1/4'"
            >
              <Logo :src="$getImage($account?.media?.original, 400)" />
            </div>
            <div
              class="hidden gap-4 md:flex md:justify-start px-5 md:items-center md:w-4/5 lg:w-2/4"
            >
              <button
                @click="$next({ name: 'search.view' })"
                :class="
                  ['location.view', 'search.view', 'photos.view'].includes($route?.name) &&
                  'underline underline-offset-8 decoration-2'
                "
                class="text-center text-base lg:text-base xl:text-lg text-primary font-bold"
              >
                My Library
              </button>
              <button
                @click="$next({ name: 'collection.list' })"
                :class="
                  collectionsPages.includes($route?.name) &&
                  'underline underline-offset-8 decoration-2'
                "
                class="text-center text-base lg:text-base xl:text-lg text-primary font-bold"
              >
                Collections
              </button>
            </div>
          </div>
          <div
            :class="
              $route?.name == 'search.view' && breakpoint?.smAndDown
                ? 'w-full flex justify-between items-center'
                : 'sm:ml-6 flex items-center'
            "
          >
            <div
              v-if="$route?.name == 'search.view' && breakpoint?.smAndDown"
              @click="emitter.emit('toggleSearchFilter')"
              class="flex justify-start pr-2 w-8/12 items-center"
            >
              <span class="text-overflow md:pl-6" :class="breakpoint.xs ? 'text-xs' : 'text-sm'"
                >Click here to start your search</span
              >
              <v-spacer />
              <Icon class="size-6 ml-1 text-primary" icon="mdi-magnify"></Icon>
            </div>
            <template v-if="$user">
              <div
                v-if="
                  breakpoint.xs && !['collection.view', 'collection.list'].includes($route?.name)
                "
                class="pl-2 pr-3"
              >
                <button @click="$next({ name: 'collection.list' })">
                  <Icon class="size-7 text-primary" icon="mdi-folder-multiple-image"></Icon>
                </button>
              </div>
              <button
                class="text-sm text-primary mx-1 hover:bg-fuchsia-50 rounded md:px-5 py-2"
                @click="emitter.emit('openDialog', true)"
              >
                <Icon v-if="breakpoint.smAndDown" class="size-7" icon="mdi-message-alert-outline" />
                <span v-else> Give feedback </span>
              </button>
              <button
                class="text-sm text-primary mx-1 hover:bg-fuchsia-50 rounded-xl pa-2"
                v-if="$route.name != 'search.view'"
                :disabled="this.$isPlanCancelled"
                @click="$next({ path: '/' })"
              >
                <Icon class="size-6" icon="mdi-magnify" />
              </button>
              <button
                class="text-sm bg-primary mx-2 text-white rounded px-5 py-2 flex"
                v-if="$isSubscribed && breakpoint?.smAndUp"
                @click="$next({ name: 'location.create' })"
                :disabled="this.$isPlanCancelled"
              >
                <v-icon class="mr-1">mdi-tray-arrow-up</v-icon> Upload
              </button>
              <PopoverMenu v-if="$user" class="ml-2" />
            </template>
            <!-- <template v-else-if="!loading">
              <button
                class="text-sm text-primary mx-2 hover:bg-fuchsia-50 rounded px-5 py-2 ring-1 ring-primary"
                @click="$loginDialog(true)"
              >
                Register/Login
              </button>
            </template> -->
          </div>
        </div>
      </div>
    </div>
    <v-progress-linear
      :active="loading"
      indeterminate
      absolute
      bottom
      height="3"
      color="primary"
      class="bottom"
    />
  </header>
</template>
<script>
import { AuthMixin } from '@mixins'
import Logo from '@components/Logo.vue'
import PopoverMenu from '@components/PopoverMenu.vue'
import Breadcrumb from '@components/Breadcrumb.vue'
import { ImageMixin } from '@mixins'

export default {
  name: 'TheHeader',

  mixins: [AuthMixin, ImageMixin],

  components: {
    Logo,
    PopoverMenu,
    Breadcrumb
  },

  data() {
    return {
      loading: false,
      collectionsPages: [
        'collection.view',
        'collection.list',
        'collection.location.view',
        'collection.location.photos.view'
      ]
    }
  },

  computed: {
    hideShowHeader() {
      if (this.$route?.meta?.hideHeader) {
        if (this.$route?.meta?.hideHeader === true) {
          return false
        } else {
          return !this.breakpoint[this.$route?.meta?.hideHeader]
        }
      } else {
        return true
      }
    }
  },

  mounted() {
    this.emitter.on('loading', this.onLoading)
  },
  beforeUnmount() {
    this.emitter.off('loading', this.onLoading)
  },
  methods: {
    onLoading(loading) {
      this.loading = loading
    }
  }
}
</script>
<style scoped>
.logo-img {
  overflow: hidden !important;
}
.v-progress-linear {
  top: auto !important;
  bottom: 0px !important;
}
</style>
