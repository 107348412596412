export default {
  mounted() {
    this.skipTabIndex('clear icon');
    this.skipTabIndex('append icon');
  },
  methods: {
    skipTabIndex(areaLabel) {
      // PATCH: to set tabIndex on clearable icon
      document.querySelectorAll(`[aria-label="${areaLabel}"]`).forEach(x => (x.tabIndex = -1));
    },
  },
};
