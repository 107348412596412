import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { authStore } from '@stores/auth'
import head from 'lodash/head'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import { StorageService } from '@services'
import SegmentMixin from '../mixins/segment.mixin'
import get from 'lodash/get'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes
})

router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  if (to.meta.requiresAuth) {
    const authData = authStore()
    const user = authData.user
    const queryAccountId = get(to, 'query.accountId')
    const paramsAccountId = get(to, 'params.accountId')
    const sessionAccountId =
      queryAccountId || paramsAccountId || StorageService.getSessionItem('accountId')
    let accountSlug = sessionAccountId ? sessionAccountId : ''
    if (!user) {
      next('/login')
    } else {
      const account = find(user.accounts, { slug: accountSlug }) || head(user?.accounts) || {}
      if (account.slug) {
        if (!accountSlug) accountSlug = account?.slug
        StorageService.setSessionItem('accountId', accountSlug)
      }
      if (to?.params?.accountId && to?.params?.accountId != accountSlug) {
        let params = { ...to.params, ...{ accountId: accountSlug } }
        next({ name: to.name, params: params })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})
router.afterEach((to, from) => {
  window.hj && window.hj('stateChange', to.fullPath);
  const category = to?.meta?.eventCategory
  const name = to?.meta?.event(to)
  const fromName = to?.meta?.event(from)
  if (name === 'Search' && name === fromName && isEqual(to.query, from.query)) {
    return
  }
  SegmentMixin.methods?.$page({ name, category })
})
export default router
