// import Analytics from 'analytics'
// import segmentPlugin from '@analytics/segment'
import { Analytics } from '@segment/analytics-node'
const analytics = new Analytics({ writeKey: import.meta.env.VITE_SEGMENT_KEY })


export default {
  methods: {
    $identity(event, user) {
      if (import.meta.env.VITE_SEGMENT === 'true') {
        analytics.identify({
          userId: event,
          traits: {
            firstName: user.firstName,
            lastName: user.lastName
          }
        })
      }
    },
    $track(event, options) {
      if (import.meta.env.VITE_SEGMENT === 'true') {
        analytics.track({
          event: event,
          properties: options
        })
      }
    },
    $page(event, options = {}) {
      if (import.meta.env.VITE_SEGMENT === 'true') {
        const { category, name } = event
       const authStore = localStorage.getItem('authStore') || null
        if(authStore){
        const { user } = JSON.parse(authStore) 
          analytics.page({
            userId: user._id, // Unique identifier for the user
            category,
            name,
          }, (err, batch) => {
            if (err) {
              console.error('Error tracking page event:');
            }
          })
        }
      }
    }
  }
}