export default {
  data() {
    return {
      preview: false,
      navigation: [
        {
          name: 'Home',
          icon: 'mdi-home-outline',
          link: { path: '/search' }
          // visible: this.hasForbidden("search.all")
        },
        {
          name: 'Edit locations',
          icon: 'mdi-square-edit-outline',
          link: { name: 'location.list' }
        },
        {
          name: 'Edit properties',
          icon: 'mdi-office-building-marker',
          link: { name: 'property.list' }
        },
        {
          name: 'Contacts',
          icon: 'mdi-account-multiple-outline',
          link: { name: 'contacts.view' }
        },
        // {
        //   name: 'Collections',
        //   icon: 'mdi-plus-box-multiple-outline',
        //   link: { name: 'collection.list' }
        // },
        {
          name: 'Sharing links',
          icon: 'mdi-link-variant',
          link: { name: 'shared.links' }
        },
        // {
        //   name: 'Favourites',
        //   icon: 'mdi-heart-outline',
        //   link: { name: 'favourites.view' }
        // },
        {
          name: 'Workspace Settings',
          icon: 'mdi-account-outline',
          link: { name: 'profile.view' }
        },
        // {
        //   name: 'Usage',
        //   icon: 'mdi-chart-donut',
        //   link: { name: 'usage.view' }
        // },
        {
          name: 'Billing',
          icon: 'mdi-currency-usd',
          link: { name: 'billing.view' }
        },
        {
          name: 'Permissions',
          icon: 'mdi-account-multiple-plus-outline',
          link: { name: 'permissions.view' }
        }
      ]
    }
  },

  methods: {
    setStepsRoute(steps) {
      for (let index = 0; index < steps.length; index++) {
        const step = steps[index]
        step.index = index
        step.next = steps[index + 1]?.value
        step.previous = steps[index - 1]?.value
      }
      return steps
    }
  }
}
