<template>
  <div :class="$attrs.class">
    <label v-if="$attrs.title" class="inline-block mb-1">{{ $attrs.title }}</label>
    <vee-field :name="$attrs.name" v-slot="{ field, errors }">
      <div class="relative">
        <input
          :type="$attrs.type"
          v-bind="field"
          :clearable="clearable && !readonly"
          :readonly="readonly"
          density="compact"
          color="primary"
          :placeholder="placeholder"
          class="text-dark block w-full rounded-md py-1.5 ring-1 ring-inset ring-gray-400 placeholder:text-gray focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6 pa-2"
          :class="{ 'ring-red-400 placeholder:text-error': (errors || []).length }"
          :style="{
            'padding-left': $attrs.prependInnerData
              ? `${$attrs.prependInnerPadding} !important`
              : ''
          }"
          @focus="$event?.target?.select()"
          @click:clear="$refs?.field?.blur()"
          @blur="applyBlurEvent && $emit('change', field?.value)"
          @keyup.enter="$emit('change', field?.value)"
          @change="$emit('autoSave')"
          autocomplete="off"
          :disabled="disabled"
          :key="key"
          :min="min"
          :max="max"
        />
        <div
          v-if="$attrs.prependInnerData"
          class="prepend-inner-data absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
        >
          <TikTokIcon v-if="$attrs.icon && $attrs.icon == 'tiktok'" />

          <Icon
            v-else-if="$attrs.icon"
            :color="iconData.color"
            class="mr-2 font-bold size-6"
            :icon="$attrs.icon"
          />
          <span v-if="$attrs.prefixText" class="mr-2 size-6">
            {{ $attrs.prefixText }}
          </span>
        </div>
        <div
          v-if="$attrs.appendInnerData && clearable && field?.value"
          class="absolute inset-y-0 right-0 pr-3 flex items-center"
        >
          <button
            class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
            @click="onClear(field)"
          >
            <Icon class="mr-2 font-bold size-6 text-gray" icon="mdi-close-circle" />
          </button>
        </div>
        <div
          v-if="counter !== null && counter >= 0"
          class="flex justify-end mt-1 text-sm"
          :class="errors.length ? 'text-error' : 'text-gray-600'"
        >
          <span>{{ field?.value?.length || 0 }} </span>/<span>{{ counter }} characters</span>
        </div>
        <div
          v-if="isLoading"
          class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
        >
          <svg
            class="w-5 h-5 text-primary animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      </div>

      <div v-if="errors.length" class="text-xs text-error mt-1 text-wrap">
        {{ errors[0] }}
      </div>
    </vee-field>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue'
import FormFieldMixin from './form-fields.mixin'

import TikTokIcon from '@components/icons/TikTokIcon.vue'

export default {
  name: 'TextField',
  inheritAttrs: false,

  mixins: [FormFieldMixin],

  props: {
    clearable: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    value: { type: null, required: false },
    error: { required: false },
    iconData: { type: Object, default: {} },
    disabled: { type: Boolean, default: false },
    applyBlurEvent: { type: Boolean, default: false },
    min: { type: Number, default: null },
    max: { type: Number, default: null },
    counter: { type: Number, default: null },
    isLoading: { type: Boolean, default: false }
  },
  data: () => ({
    innerValue: null,
    key: 0
  }),

  components: {
    TikTokIcon,
    Icon
  },

  methods: {
    onClear(field) {
      field.value = null
      this.$emit('onClear', null)
      this.key++
    }
  }
}
</script>
<style scoped>
input:focus {
  border-color: #6e0d5e !important;
  outline: none !important;
}
</style>
