<template>
  <div>
    <v-tabs v-model="tabInput" varient="flat" fixed-tabs bg-color="tertiary" color="primary" grow>
      <v-tab
        :class="{ 'font-bold': tabInput == 0, 'opacity-75': tabInput == 1 }"
        class="text-none w-50"
      >
        Create account
      </v-tab>
      <v-tab
        :class="{ 'font-bold': tabInput == 1, 'opacity-75': tabInput == 0 }"
        class="text-none w-50"
        >Sign in</v-tab
      >
    </v-tabs>

    <v-window v-model="tabInput">
      <v-window-item>
        <RegisterBase />
      </v-window-item>

      <v-window-item>
        <LoginBase />
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import LoginBase from '@components/auth/LoginBase.vue'
import RegisterBase from '@components/auth/RegisterBase.vue'

export default {
  name: 'AuthBase',
  components: {
    LoginBase,
    RegisterBase
  },
  props: {
    tab: { type: Number, default: 1 },
    options: { type: Object, required: false }
  },
  data() {
    return {
      tabInput: this.tab,
      referrer: null
    }
  },
  mounted() {},
  methods: {}
}
</script>