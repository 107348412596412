 <template>
  <div class="mb-3">
    <label v-if="$attrs.title" class="inline-block mb-2">{{ $attrs.title }}</label>
    <vee-field :name="$attrs.name" :bails="false" v-slot="{ field, errors }">
      <div class="relative">
        <!-- :type="show ? 'text' : 'password'" -->
        <input
          density="compact"
          :type="show ? 'text' : 'password'"
          v-bind="field"
          variant="outlined"
          color="primary"
          :placeholder="placeholder"
          class="block w-full rounded-md py-1.5 ring-1 ring-inset ring-gray-400 placeholder:text-gray focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6 pa-2"
          :class="{ 'ring-red-400 placeholder:text-error': (errors || []).length }"
          :style="{ 'padding-right': '40px !important' }"
          autocomplete="current-password"
          :hint="
            $attrs.hint ||
            'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter and one number'
          "
        />
        <div class="absolute inset-y-0 right-0 pl-3 flex items-center cursor-pointer">
          <Icon
            class="mr-2 font-bold size-6 text-primary"
            :icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click="show = !show"
          />
        </div>
      </div>
      <div class="text-xs text-error flex items-center mt-2" v-if="errors.length">
        {{ errors[0] }}
      </div>
    </vee-field>
  </div>
</template>

<script>
export default {
  name: 'PasswordField',
  props: {
    placeholder: { type: String, default: '' }
    //value: { type: null, required: true }
  },
  data: () => ({
    innerValue: '',
    show: false
  })
}
</script>
<style scoped>
input:focus {
  border-color: #6e0d5e !important;
  outline: none !important;
}
</style>
