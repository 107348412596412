import get from 'lodash/get'
import find from 'lodash/find'
import head from 'lodash/head'
import { authStore } from '@stores/auth'
import { StorageService } from '@services'

export default {
  beforeEnter: (to, from, next) => {
    let authData = authStore()

    const user = authData.user
    const sessionAccountId = StorageService.getSessionItem('accountId')
    const accountSlug = sessionAccountId ? sessionAccountId : head(user?.accounts)?.slug || ''
    const account = find(user.accounts, { slug: accountSlug }) || {}
    const isSubscribed = get(account, 'plans.subscriptionId')
    if (!account.isActive) {
      return next({ path: '/onboarding/profile', query: { accountId: sessionAccountId } });
    } else if (!isSubscribed) {
      return next({ path: '/onboarding/pricing', query: { accountId: sessionAccountId } });
    } else {
      return next()
    }
  }
}