<template>
  <v-dialog
    v-model="dialog"
    :width="options.width"
    :persistent="options.persistent"
    @click:outside="!options.persistent && onClick(false)"
  >
    <v-card class="pa-2">
      <v-card-text class="pa-0 pa-sm-3">
        <v-card-title center bold class="my-2 text-sm-h5 text-wrap text-center">
          <div v-html="options.title"></div>
        </v-card-title>

        <v-card-subtitle v-if="options.subtitle" class="p-mb-0 text-center text-sm-h6">
          <div v-html="options.subtitle"></div>
        </v-card-subtitle>
      </v-card-text>

      <v-card-actions>
        <div class="mx-auto">
          <v-btn
            v-if="!options.hideCancelButton"
            variant="flat"
            class="text-none font-bold mx-1"
            :size="'large'"
            color="dark"
            @click="onClick(false)"
          >
            Cancel
          </v-btn>

          <v-btn
            variant="flat"
            class="text-none font-bold"
            :size="'large'"
            color="primary"
            @click="onClick(true)"
          >
            {{ options.confirmButtonText || 'Yes' }}
          </v-btn>
        </div>
      </v-card-actions>
      <v-card-subtitle v-if="options.footer" class="text-center">
        <div v-html="options.footer"></div>
      </v-card-subtitle>
    </v-card>
  </v-dialog>
</template>
  
  <script>
export default {
  name: 'TheAlert',

  data() {
    return {
      dialog: false,
      title: null,
      options: {},
      subtitle: null,
      callback: null
    }
  },
  mounted() {
    this.emitter.on('confirm', this.onConfirm)
  },
  beforeUnmount() {
    this.emitter.off('confirm', this.onConfirm)
  },
  methods: {
    onClick(value) {
      this.dialog = false
      if (typeof this.callback === 'function') {
        this.callback(value)
      }
    },
    onConfirm(options) {
      this.options.title = `<p>${options?.title}</p>`
      this.options.subtitle = options?.subtitle
      this.options.footer = options?.footer
      this.options.width = options?.width || '800px'
      this.options.confirmButtonText = options?.confirmButtonText
      this.options.hideCancelButton = options?.hideCancelButton
      this.options.persistent = !!options?.persistent
      this.callback = options?.callback
      this.dialog = true
    }
  }
}
</script>
  