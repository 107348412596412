<template>
  <TransitionRoot as="template" :show="dialog">
    <Dialog class="relative z-10">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-24">
            <TransitionChild
              as="template"
              enter="transition ease-in-out duration-500 transform"
              enter-from="-translate-x-full"
              enter-to="translate-x-0"
              leave="transition ease-in-out duration-500 transform"
              leave-from="translate-x-0"
              leave-to="-translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex mt-20 flex-col overflow-y-scroll bg-white pb-6 shadow-xl">
                  <div class="relative flex-1 px-4 sm:px-6">
                    <div class="absolute top-0 right-3 z-1 pb-3">
                      <button @click="$emit('closeDialog')">
                        <Icon class="size-10 text-dark" icon="mdi-chevron-left" />
                      </button>
                    </div>
                    <div class="py-4 h-screen">
                      <SideBarContent :parentData="parentData" />
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import SideBarContent from '@components/collections/SideBarContent.vue'
export default {
  name: 'SideBarDrawer',

  props: {
    dialog: { type: Boolean, default: false },
    parentData: { type: Object, default: null }
  },

  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    SideBarContent
  }
}
</script>