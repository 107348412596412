<template>
  <div class="flex">
    <AppSidebar class="fixed"> </AppSidebar>
    <main class="lg:pl-72 w-screen">
      <div class="w-full">
        <router-view />
      </div>
    </main>
  </div>
</template>
<script>
import AppSidebar from '../components/AppSidebar.vue'
export default {
  components: { AppSidebar }
}
</script>
