import _ from 'lodash'
import Api from './api/api.service'

const baseServiceUrl = 'permission'

export default {
  get() {
    return Api().get(`/${baseServiceUrl}`)
  },
  getAll() {
    return Api().get(`/${baseServiceUrl}/all`)
  },
}
