<template>
  <div :class="$attrs.class">
    <label v-if="$attrs.title" class="inline-block mb-1">
      {{ $attrs.title }}
    </label>
    <vue-tel-input :value="phone" @input="onInput" default-country="gb"></vue-tel-input>
  </div>
</template>

<script>
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

export default {
  components: {
    VueTelInput
  },
  props: {
    modelValue: { type: null, required: false }
  },
  data() {
    return {
      phone: this.modelValue
    }
  },
  methods: {
    onInput(_, phoneObject) {
      if (phoneObject?.number) {
        this.phone = phoneObject.number
        this.$emit('setValue', { phone: this.phone, countryCode: phoneObject.countryCode })
        this.$emit('change')
      }
    }
  }
}
</script>
<style scoped>
.vue-tel-input {
  height: 42px !important;
  margin-bottom: 18px !important;
}
</style>
