<template>
  <div class="h-full">
    <ActionBar :key="$route?.name" />
    <div class="flex h-full">
      <collectionSidebar :key="key" :parentData="parent" />
      <main class="w-full pa-6 h-screen overflow-y-auto">
        <div class="w-full">
          <router-view />
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import collectionSidebar from '@components/collections/Sidebar.vue'
import ActionBar from '@components/collections/ActionBar.vue'
export default {
  name: 'CollectionNavigationLayout',

  components: { collectionSidebar, ActionBar },

  data() {
    return {
      parent: null,
      key: 0
    }
  },

  mounted() {
    this.emitter.on('setParentData', this.setParentData)
  },

  beforeUnmount() {
    this.emitter.off('setParentData', this.setParentData)
  },

  methods: {
    setParentData(value) {
      this.parent = value
      this.key++
    }
  }
}
</script>
