import { LINK_STATUS, LINK_MANAGE } from '@constants'
import cloneDeep from 'lodash/cloneDeep'
import remove from 'lodash/remove'
import { LinkService } from '@services'
export default {
  data() {
    return {
      actionsList: [
        {
          name: 'Copy URL',
          value: 'copyURL',
          show: true,
          icon: 'mdi-content-copy'
        },
        {
          name: 'Edit',
          value: 'edit',
          show: true,
          icon: 'mdi-pencil-box-outline'
        },
        {
          name: 'Publish',
          value: 'published',
          show: true,
          icon: 'mdi-eye-outline'
        },
        {
          name: 'Unpublish',
          value: 'unpublished',
          show: true,
          icon: 'mdi-eye-off-outline'
        },
        {
          name: 'Delete',
          value: 'delete',
          show: true,
          icon: 'mdi-delete'
        }
      ]
    }
  },
  methods: {
    onEdit(data) {
      let routeName = 'collection.share.edit'
      let params = { shareId: data?.linkId }
      if (data.location) {
        routeName = 'location.share.edit'
        params.locationId = data.location?.slug
        params.propertyId = data.location?.propertyId.slug
      } else {
        params.collectionId = data.collection?.slug
      }

      const nextPage = {
        name: routeName,
        params
      }
      if (this.$route.name == 'collection.view') {
        this.$next(nextPage)
      } else {
        this.$openBlankWindow(nextPage)
      }
    },
    onCopy(data) {
      if (data?.linkId) {
        const link = this.getURL(data)
        this.$copy(link)
      }
    },
    getActionsList(link) {
      const actions = cloneDeep(this.actionsList)
      remove(actions, function (obj) {
        if (link.status === LINK_STATUS.DRAFT && obj.value === LINK_STATUS.UNPUBLISHED) return true
        else if (obj.value === link.status) return true
      })
      return actions
    },
    viewLink(data) {
      if (data?.linkId) {
        const routeName = data?.collection ? 'collection.share.view' : 'location.share.view'
        this.$openBlankWindow({
          name: routeName,
          params: { shareId: data?.linkId }
        })
      }
    },
    getURL(data) {
      return data?.isFile
        ? `${this.baseURL}/l/${data?.linkId}`
        : `${this.baseURL}/c/${data?.linkId}`
    },

    menuAction(key, link) {
      switch (key.value) {
        case 'delete':
          this.onDelete(link)
          break
        case 'edit':
          this.onEdit(link)
          break

        case 'copyURL':
          this.onCopy(link)
          break

        case LINK_STATUS.UNPUBLISHED:
          this.onChangeStatus(link)
          break

        case LINK_STATUS.PUBLISHED:
          this.onChangeStatus(link)
          break
        default:
          break
      }
    },
    async onChangeStatus(link) {
      const payload = {
        _id: link?._id,
        status:
          link.status == LINK_STATUS.DRAFT || link.status == LINK_STATUS.UNPUBLISHED
            ? LINK_STATUS.PUBLISHED
            : LINK_STATUS.UNPUBLISHED
      }
      const response = await LinkService.save(payload)

      if (response) {
        await this.getAllSharedLinks()
      }
    },
    async onDelete(value) {
      const confirm = await this.$confirm('DELETE_LINK')
      if (confirm) {
        const params = {
          linkIds: [value?._id],
          operation: LINK_MANAGE.HIDE
        }
        const response = await LinkService.manage(value?._id, params)
        if (response) await this.getAllSharedLinks()
      }
    }
  }
}
