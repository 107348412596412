import { defineStore } from 'pinia'
import { UserService } from '@services'

export const authStore = defineStore('authStore', {
  state: () => ({
    user: null
  }),
  persist: true,
  actions: {
    async getUser() {
      const response = await UserService.get()
      if (response) {
        this.user = response.data
      }
    }
  }
})
