<template>
  <v-dialog v-model="dialog" width="450" persistent>
    <v-card>
      <AuthBase
        v-if="dialog"
        :options="options"
        dialog
        :tab="options.tab"
        @reject="onClick(false)"
        @resolve="onClick(true)"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import AuthBase from '@components/auth/AuthBase.vue'

export default {
  name: 'AuthDialog',

  components: {
    AuthBase
  },

  data() {
    return {
      dialog: false,
      options: {}
    }
  },

  mounted() {
    this.emitter.on('auth-dialog', this.setDialog)
  },

  beforeUnmount() {
    this.emitter.off('auth-dialog', this.setDialog)
  },

  methods: {
    setDialog(dialog, options, callback) {
      this.options = options || {}
      this.dialog = dialog
      this.callback = callback
    },

    onClick(value) {
      this.dialog = false
      if (typeof this.callback === 'function') {
        this.callback(value)
      }
    }
  }
}
</script>
