<template>
  <header class="bg-white z-1 border-b-2 position-sticky top-16">
    <div class="px-4 sm:px-1 lg:px-8 flex md:justify-between items-center">
      <button v-if="breakpoint.smAndDown" @click="sideBarDraweToggle = true">
        <Icon icon="mdi-menu" class="text-primary size-6"></Icon>
      </button>
      <div class="overflow-x-auto w-9/12 md:w-9/12 lg:w-10/12">
        <div class="flex justify-between items-center">
          <Breadcrumb :key="key" />
        </div>
      </div>
      <template v-if="breakpoint.mdAndUp">
        <div class="flex items-center gap-4">
          <template v-if="$route?.params?.collectionId">
            <button
              class="text-primary font-semibold ring-1 ring-primary px-2 md:px-3 py-1.5 rounded flex text-nowrap"
              @click="shareCollection"
            >
              <Icon class="text-primary size-6 pr-1" icon="mdi-share-variant"></Icon>
              <span class="hidden md:block text-base"> Share </span>
            </button>
            <button
              class="text-primary font-semibold ring-1 ring-primary px-2 md:px-3 py-1.5 rounded flex text-nowrap"
              @click="
                $next({
                  name: 'collection.manage',
                  params: { collectionId: $route?.params?.collectionId }
                })
              "
            >
              <Icon class="text-primary size-6 pr-1" icon="mdi-pencil"></Icon>
              <span class="hidden md:block text-base"> Edit locations </span>
            </button>
          </template>
          <button
            class="text-primary font-semibold ring-1 ring-primary px-2 md:px-3 py-1.5 rounded flex text-nowrap"
            @click="createCollectionDialogToggle = true"
          >
            <Icon class="text-primary size-6 pr-1" icon="mdi-plus"></Icon>
            <span class="hidden md:block text-base"> New collection </span>
          </button>
        </div>
      </template>
      <template v-else>
        <ActionMenu
          v-if="actionsList"
          :actions="actionsList"
          @menuAction="menuAction"
          :menuItemsClass="'right-0 w-40'"
        />
      </template>
    </div>
  </header>
  <CreateCollectionDialog
    :dialog="createCollectionDialogToggle"
    @closeDialog="createCollectionDialogToggle = false"
  />
  <SideBarDrawer
    :key="key"
    :parentData="parent"
    :dialog="sideBarDraweToggle"
    @closeDialog="sideBarDraweToggle = false"
  />
</template>

<script>
import CreateCollectionDialog from '@components/collections/CreateCollectionDialog.vue'
import Breadcrumb from '@components/Breadcrumb.vue'
import ActionMenu from '@components/ActionMenu.vue'
import SideBarDrawer from './SideBarDrawer.vue'
export default {
  name: 'ActionBar',

  components: {
    Breadcrumb,
    CreateCollectionDialog,
    ActionMenu,
    SideBarDrawer
  },

  data() {
    return {
      key: 0,
      createCollectionDialogToggle: false,
      actionsList: [
        {
          name: 'Share',
          value: 'share',
          icon: 'mdi-share-variant',
          show: true
        },
        {
          name: 'Edit locations',
          value: 'editLocations',
          icon: 'mdi-pencil',
          show: true
        },
        {
          name: 'New collection',
          value: 'createCollection',
          icon: 'mdi-plus',
          show: true
        }
      ],
      sideBarDraweToggle: false,
      parent: null
    }
  },

  mounted() {
    this.emitter.on('updateBreadcrumb', this.updateBreadcrumb)
    this.emitter.on('setParentData', this.setParentData)
    if (!this.$route?.params?.collectionId)
      this.actionsList = this.actionsList.filter((action) => action.value === 'createCollection')
  },

  beforeUnmount() {
    this.emitter.off('updateBreadcrumb', this.updateBreadcrumb)
    this.emitter.off('setParentData', this.setParentData)
  },

  methods: {
    updateBreadcrumb() {
      this.key++
    },

    shareCollection() {
      this.emitter.emit('openSharedLinksModel', this.$route?.params?.collectionId)
    },

    menuAction(key) {
      switch (key.value) {
        case 'share':
          this.shareCollection()
          break
        case 'editLocations':
          this.$next({
            name: 'collection.manage',
            params: { collectionId: this.$route?.params?.collectionId }
          })
          break
        case 'createCollection':
          this.createCollectionDialogToggle = true
          break

        default:
          break
      }
    },

    setParentData(value) {
      this.parent = value
      this.key++
    }
  }
}
</script>
