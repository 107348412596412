import authRoutes from './auth.route'
import homeRoutes from './home.route'
import pagesRoutes from './pages.route'
import userRoutes from './user.route'
import profileRoutes from './profile.route'
import publicRoutes from './public.route'
import DefaultLayout from '@layouts/DefaultLayout.vue'
import { defineAsyncComponent } from 'vue'

let childrenRoutes = []

childrenRoutes.push(...authRoutes, ...pagesRoutes, ...userRoutes, ...profileRoutes)
const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/search',
    component: DefaultLayout,
    children: childrenRoutes,
    meta: {
      link: (route) => `/`,
      breadcrumb: (name) => name || 'Library'
    }
  }
]
routes.push(...publicRoutes)

export default routes
