<script setup>
// import { RouterView } from 'vue-router'
</script>

<template>
  <RouterView />
  <TheSnackbar />
  <TheAlert />
</template>

<script>
import TheSnackbar from './components/TheSnackbar.vue'
import TheAlert from './components/TheAlert.vue'

export default {
  components: { TheSnackbar, TheAlert }
}
</script>

<style scoped></style>
