export default {
  getItem(key) {
    return localStorage.getItem(key)
  },
  setItem(key, item) {
    localStorage.setItem(key, item)
  },
  removeItem(key) {
    localStorage.removeItem(key)
  },
  getSessionItem(key) {
    return sessionStorage.getItem(key)
  },
  setSessionItem(key, item) {
    sessionStorage.setItem(key, item)
  },
  removeSessionItem(key) {
    sessionStorage.removeItem(key)
  }
}
