<template>
  <div class="h-screen sticky left-0 top-20">
    <div class="hidden h-screen lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col">
      <div
        class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6"
      >
        <SideBarContent :parentData="parentData" />
      </div>
    </div>
  </div>
</template>

<script>
import SideBarContent from './SideBarContent.vue'
export default {
  name: 'CollectionSidebar',

  props: {
    parentData: { type: Object, default: null }
  },

  components: {
    SideBarContent
  }
}
</script>
