import { COLORS } from '@constants'

// Vuetify
import '@/assets/scss/variables.scss'
import '@/assets/scss/vuetify.scss'
import '@/assets/scss/theme.scss'
import { createVuetify } from 'vuetify'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// import * as icons from '../plugins/mdi.plugin';

const light = {
  dark: false,
  colors: {
    primary: COLORS.PRIMARY,
    secondary: '#86C34E',
    lightGrey: '#f7f7f7',
    tertiary: '#e6e6e6',
    dark: '#616161',
    darkOnly: '#616161',
    light: COLORS.LIGHT,
    lightOnly: COLORS.LIGHT,
    grey: COLORS.GREY,
    error: '#ff5252',
    info: '#4c8ac2',
    success: '#4CAF50',
    warning: '#FB8C00'
  }
}

export default createVuetify({
  theme: {
    defaultTheme: 'light',
    themes: {
      light
    }
  },
  icons: {
    defaultSet: 'mdi'
  },
  components,
  directives
})
