import { StorageService, UserService } from '@services'
import { authStore } from '@stores/auth'
import { permissionStore } from '@stores/permissions'

export default {
  computed: {
    $user() {
      let authData = authStore()
      let userData = null
      if (authData) {
        userData = authData.user
      }
      return userData
    },
    $account: {
      cache: false,
      get() {
        const accountId = StorageService.getSessionItem('accountId') || null
        const account = accountId
          ? this.$user?.accounts?.find((account) => account.slug == accountId)
          : null
        return account
      }
    },
    $accountId: {
      cache: false,
      get() {
        let accountId = StorageService.getSessionItem('accountId')
        if (!accountId) {
          accountId = this.$account ? this.$account.slug : null
        }
        return accountId
      },
      set(accountId) {
        if (accountId) {
          StorageService.setSessionItem('accountId', accountId)
          this.$setPermission(accountId)
        } else {
          StorageService.removeSessionItem('accountId')
        }
      }
    },
    $isSubscribed: {
      cache: false,
      get() {
        return !!(this.$account?.plans && this.$account?.plans?.subscriptionId)
      }
    },
    $isPlanCancelled: {
      cache: false,
      get() {
        return !!(this.$account?.plans && this.$account?.plans?.isCanceled == true)
      }
    },
    $permissionList: {
      cache: false,
      get() {
        const permissionData = permissionStore()
        if (!permissionData) {
          return null
        }
        return permissionData.permissionList
      }
    },
    $permissions: {
      cache: false,
      get() {
        const permissionData = permissionStore()
        if (!permissionData) {
          return null
        }
        return permissionData.accountPermission
      }
    }
  },
  methods: {
    setDefaultAccountId() {
      const [account] = this.$user.accounts
      if (account) {
        this.$accountId = account.slug
      }
    },
    setUser(user) {
      if (this.$user) {
        this.$user = { ...this.$user, ...user }
      }
    },
    setSessionId(loggedInSessionId = false) {
      //if (this.$segment) {
      let sessionId = null
      if (loggedInSessionId) {
        sessionId = loggedInSessionId
      } else {
        sessionId = StorageService.getItem('sessionId')
      }
      if (!sessionId || new Date() > new Date(Number(sessionId))) {
        const date = new Date()
        date.setHours(date.getHours() + import.meta.env.VITE_APP_SESSION_TIME_HOUR)
        date.setMinutes(date.getMinutes() + import.meta.env.VITE_APP_SESSION_TIME_MINUTE)
        sessionId = date.getTime()
      }
      StorageService.setItem('sessionId', sessionId)
      // }
    },
    async updateUserDetails(user) {
      this.$loading.updateUser = true
      const secondaryPhones = user.secondaryPhones || []

      // remove this data from payload
      await secondaryPhones.forEach((object) => {
        object.isPrimary = false
        delete object._index
        delete object._isVerified
        delete object._loading
        delete object._invalid
      })
      const primaryPhoneIndex = secondaryPhones.findIndex(
        (secondary) => secondary.phone === user.phone
      )
      if (primaryPhoneIndex >= 0) {
        secondaryPhones[primaryPhoneIndex].isPrimary = true
        secondaryPhones[primaryPhoneIndex].isVerified = true
        // secondaryPhones[primaryPhoneIndex].notification = { system: true };
      } else {
        secondaryPhones.push({
          notification: { system: true },
          phone: this.user.phone,
          isVerified: true,
          isPrimary: true,
          firstName: this.user?.firstName,
          lastName: this.user.lastName
        })
      }
      const response = await UserService.save(
        this.user._id,
        this.user.phone && { phone: this.user.phone, secondaryPhones },
        true
      )

      if (response) {
        this.emitter.emit('success', 'Changes saved successfully')
      }

      this.$loading.updateUser = false
    },
    setAccountId(accountId) {
      if (this.$user) {
        if (accountId) {
          if (this.$user?.accounts?.some((account) => account.slug == accountId)) {
            this.$accountId = accountId
          } else {
            this.setDefaultAccountId()
          }
        } else {
          this.setDefaultAccountId()
        }
      } else {
        this.$accountId = null
      }
      this.emitter.emit('updateHeader')
    },
    async $setAllPermission() {
      if (this.$user) {
        const permissionData = permissionStore()
        await permissionData.getPermissionList()
      }
    },
    async $setPermission() {
      if (this.$accountId) {
        const permissionData = permissionStore()
        await permissionData.getAccountPermission()
      }
    }
  }
}
