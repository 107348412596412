import EventService from '../event.service'

const options = {
  baseURL: '',
  Authorization: '',
  sanitize: () => { },
  requestHandler: () => {
    EventService.emit('loading', true)
    // store.set('app/loading', true);
  },
  responseHandler: (response, options) => {
    // store.set('app/loading', false);
    if (!options.hideSuccessMessage && response.data.message) {
      EventService.emit('success', response?.data?.message)
    }
    EventService.emit('loading', false)
  },
  requestErrorHandler: () => {
    console.log('call requestErrorHandler from api folder')
  },
  responseErrorHandler: (response, options) => {
    if (response && response.status == 401) {
      EventService.emit('error', 'Your session has ended. Please log in again.')
      EventService.emit('logout')
    } else if (!options.hideErrorMessage && response?.data?.error) {
      EventService.emit('error', response.data.error)
    } else if (!options.hideErrorMessage) {
      EventService.emit('error', 'Something went wrong! try again.')
    }
    EventService.emit('loading', false)
  }
}

export default {
  options,
  use: (_options) => {
    Object.assign(options, _options)
  }
}
