function toLocation(name, location = {}, options = {}) {
  const propertyId = location?.propertyId?.slug || options.propertyId || options.params?.propertyId
  const locationId = location?.slug || options.params?.locationId
  // prettier-ignore

  const accountId =
    options.accountId?.slug ||
    options.account?.slug ||
    location.accountId?.slug ||
    location.account?.slug ||
    options.params?.accountId

  const params = { locationId, accountId }
  if (propertyId) {
    params.propertyId = propertyId
  }
  return {
    name,
    params: params,
    query: options.query
  }
}

function toPhotos(name, location = {}, options = {}) {
  const propertyId = location?.propertyId?.slug || options.propertyId
  const locationId = location.slug || options.params?.locationId
  // prettier-ignore
  const accountId = options.accountId?.slug || options.account?.slug || location.accountId?.slug || location.account?.slug || options.params?.accountId;

  const params = { propertyId, locationId, accountId }
  if (options.rank) {
    params.rank = options.rank
  }
  return {
    name,
    params: params,
    query: options.query
  }
}

export default {
  methods: {
    $toNext(to = {}) {
      to.params = to.params || {}
      to.params.accountId = to.params.accountId || this.$accountId
      to.query = to.query || {}

      return to
    },
    $next(to, replace) {
      if (replace) {
        this.$router.replace(this.$toNext(to)).catch(() => {})
      } else {
        this.$router.push(this.$toNext(to)).catch(() => {})
      }
    },
    $previous() {
      this.$router.go(-1)
    },
    $replaceState(to) {
      const { href } = this.$router.resolve(to)
      window.history.replaceState({}, null, href)
    },
    // location edit
    $toEditLocation(location = {}, options = {}) {
      const to = toLocation('location.edit', location, options)
      to.params.step = 'basic-info'
      return this.$toNext(to)
    },
    $editLocation(location = {}, options = {}) {
      this.$next(this.$toEditLocation(location, options))
    },
    $viewLocation(location = {}, options = {}) {
      const to = toLocation('location.view', location, options)
      return this.$next(to)
    },
    $toViewVenue(venue = {}, venueType, options = {}) {
      if (venueType === 'media') {
        if (venue?.parentId) {
          options.query = { ...options.query, group: venue.parentId }
        }
        return this.$toViewPhotos(venue.locationId, options)
      } else if (venueType === 'location') {
        return this.$toViewLocation(venue, options)
      }
    },
    $toViewLocation(location = {}, options = {}) {
      const to = toLocation('location.view', location, options)
      return this.$toNext(to)
    },
    $toViewPhotos(location = {}, options = {}) {
      const to = toPhotos('photos.view', location, options)
      return this.$toNext(to)
    },
    $toViewLocationPhotos(location = {}, options = {}) {
      const to = toPhotos('photos', location, options)
      return this.$toNext(to)
    },
    $openBlankWindow(to, target = '_blank') {
      const route = this.$router.resolve(to)
      const href = route.href.replace(/\/(?=\?|$)/, '')
      window.open(href, target)
    },
    switchAccount(account, skip) {
      if (skip) {
        this.setAccountId(account.slug)
        return
      }

      const route = { ...this.$route }

      if (this.$route?.meta?.redirect) {
        route.name = this.$route?.meta?.redirect
      }

      route.query = route.query || {}
      route.query.accountId = account.slug
      this.$openBlankWindow(route, account.slug)
    },
    $shareLocation(location = {}, options = {}) {
      const to = toLocation('location.share', location, options)
      return this.$next(to)
    },

    $editProperty(property = {}, options = {}) {
      this.$next({
        name: 'property.edit',
        params: { propertyId: property.id }
      })
    }
  }
}
