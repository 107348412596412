import Api from './api/api.service'
import _ from 'lodash'

const baseServiceUrl = 'location'

export default {
  save(location, hideSuccessMessage) {
    if (location.isNew) {
      return Api({ hideSuccessMessage }).post(
        `${baseServiceUrl}/create`,
        _.omit(location, ['isNew', 'process', 'isAutoSave', 'stepName'])
      )
    } else {
      return Api({ hideSuccessMessage }).patch(
        `${baseServiceUrl}/${location._id}`,
        _.omit(location, ['_id', 'isNew', 'process', 'stepName', 'contact._id', 'socialLinks._id'])
      )
    }
  },
  get(params) {
    return Api({ displayLoader: true }).post(`${baseServiceUrl}`, params)
  },
  getLocationsMarker() {
    return Api({ displayLoader: false }).get(`${baseServiceUrl}/markers`)
  },
  getByLocationId(locationId, params = {}) {
    if (locationId) {
      return Api({
        hideLoader: !!params.unique,
        hideSuccessMessage: !!params.unique
      }).get(`${baseServiceUrl}/${locationId}`, { params })
    }
  },
  getByAccountId(params) {
    return Api().get(`${baseServiceUrl}/list`, { params })
  },
  getAllLocations(params) {
    return Api().post(`${baseServiceUrl}/getAllLocations`, params)
  },
  delete(id) {
    if (id) {
      return Api().delete(`${baseServiceUrl}/${id}`)
    }
  },
  changeStatus(locationId, payload) {
    return Api().patch(`${baseServiceUrl}/status/${locationId}`, payload)
  },
  checkLocationLimit() {
    return Api().get(`${baseServiceUrl}/limit`)
  },
  getReferenceNo() {
    return Api().get(`${baseServiceUrl}/referenceNo`)
  },
  startTagging(locationId, payload) {
    return Api().patch(`${baseServiceUrl}/start-tagging/${locationId}`, payload)
  },
  getContacts(id, params) {
    return Api().get(`${baseServiceUrl}/${id}/contact/list`, { params })
  }
}
