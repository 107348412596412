<template>
  <div class="mb-4 pl-6">
    <vee-field
      :name="$attrs.name"
      :id="$attrs.name"
      type="checkbox"
      value="1"
      class="w-4 h-4 float-left -ml-6 mt-1 rounded inline-block"
    />
    <label class="inline-block" :for="$attrs.name">
      <div>
        I accept the
        <a
          class="text-primary"
          target="_blank"
          :href="`${this.baseURL}/terms-conditions`"
          @click.stop
        >
          Terms of Use
        </a>
        and
        <a
          class="text-primary"
          target="_blank"
          :href="`${this.baseURL}/terms-policies`"
          @click.stop
        >
          Privacy policy
        </a>
      </div>
    </label>
    <br />
    <ErrorMessage class="text-xs text-error flex items-center mt-2" :name="$attrs.name" />
  </div>
</template>

<script>
export default {
  name: 'TermPolicyField'
}
</script>
