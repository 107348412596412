<template>
  <v-dialog v-model="dialog" :width="breakpoint.mdAndUp ? 600 : 350">
    <v-card :width="breakpoint.mdAndUp ? 600 : 350" :class="breakpoint.mdAndUp && 'pa-5'">
      <v-btn
        variant="flat"
        icon="mdi-close-circle-outline"
        class="position-absolute close-btn"
        @click="dialog = false"
      ></v-btn>
      <v-card-title class="font-weight-bold text-wrap"
        >How would you like to provide feedback?</v-card-title
      >
      <v-card-text
        class="d-flex justify-space-between align-center"
        :class="breakpoint.smAndDown && 'pa-2'"
      >
        <v-row class="ma-0">
          <v-col cols="12" md="6" class="d-flex justify-center">
            <v-btn
              block
              :stacked="breakpoint.mdAndUp"
              variant="outlined"
              color="primary"
              :size="breakpoint.smAndDown ? 'medium' : 'x-large'"
              class="text-none pa-5 feedback-btn"
              :height="breakpoint.mdAndUp && 178"
              rounded="lg"
              :href="'https://superscout.canny.io'"
              target="_black"
            >
              <v-icon
                :class="breakpoint.smAndDown && 'mr-2'"
                :size="breakpoint.smAndDown ? 'large' : 90"
                >mdi-lightbulb-on-outline</v-icon
              >
              Suggest changes publicly</v-btn
            >
          </v-col>
          <v-col cols="12" md="6" class="d-flex justify-center">
            <v-btn
              block
              :stacked="breakpoint.mdAndUp"
              variant="outlined"
              color="primary"
              :size="breakpoint.smAndDown ? 'medium' : 'x-large'"
              class="text-none pa-5 feedback-btn"
              :height="breakpoint.mdAndUp && 178"
              rounded="lg"
              @click="mailTo('support@superscout.ai')"
            >
              <v-icon
                :class="breakpoint.smAndDown && 'mr-2'"
                :size="breakpoint.smAndDown ? 'large' : 90"
                >mdi-email-outline</v-icon
              >
              Email us privately
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'FeedbackModel',

  data() {
    return {
      dialog: false
    }
  },

  mounted() {
    this.emitter.on('openDialog', (val) => {
      this.dialog = val
    })
  },

  beforeUnmount() {
    this.emitter.off('openDialog', (val) => {
      this.dialog = val
    })
  }
}
</script>

<style>
.close-btn {
  top: 0;
  right: 0;
}
.feedback-btn {
  border: 2px solid !important;
}
</style>