import forIn from 'lodash/forIn'

export default {
  getRandomBoolean() {
    return Math.random() >= 0.5
  },
  safePush(array, item, key) {
    if (
      !array.some((_item) => {
        return key ? _item[key] == item[key] : _item == item
      })
    ) {
      array.push(item)
    }
  },

  slugify(string = '', replacement) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    const str = string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w-]+/g, '-') // Remove all non-word characters
      .replace(/--+/g, '-') // Replace multiple - with single -
    // .replace(/^-+/, '') // Trim - from start of text
    // .replace(/-+$/, ''); // Trim - from end of text

    if (replacement) {
      return str.replace(/-/g, replacement)
    } else {
      return str
    }
  },

  parseHTML(string) {
    return (string || '')
      .replace(/<\/p><p>/g, ' ') // add white space
      .replace(/&nbsp;/g, ' ') // replace &nbsp; with white space
      .replace(/<[^>]+>/g, '') // remove html tags
      .replace(/\s+/, ' ') // remove multiple white space
      .trim()
  },

  // https://stackoverflow.com/questions/16251822/array-to-comma-separated-string-and-for-last-tag-use-the-and-instead-of-comma
  join(array) {
    return array.join(', ').replace(/,(?!.*,)/gim, ', and')
  },

  getMeta(object = {}, route = {}) {
    const social = ['twitter', 'og']

    object.url = route.path
      ? `${process.env.VUE_APP_BASE_URL}${route.path}`
      : process.env.VUE_APP_BASE_URL

    const meta = social.reduce((array, item) => {
      forIn(object, (value, key) => {
        if (value) {
          const hid = `${item}:${key}`

          const obj = {
            hid,
            content: value
          }

          if (item == 'twitter') {
            obj.name = hid
          }

          if (item == 'og') {
            obj.property = hid
          }

          array.push(obj)
        }
      })

      return array
    }, [])

    if (object.description) {
      meta.push({
        hid: 'description',
        name: 'description',
        content: object.description
      })
    }

    return meta
  },

  toFixedNumber(number, fractionDigits) {
    return number && parseFloat(Number(number).toFixed(fractionDigits || 3))
  },
  // Generating random locations nearby 120 meters
  getNearByCoordinates(y0, x0) {
    const r = 120 / 111300, // = 120 meters
      u = Math.random(),
      v = Math.random(),
      w = r * Math.sqrt(u),
      t = 2 * Math.PI * v,
      x = w * Math.cos(t),
      y1 = w * Math.sin(t),
      x1 = x / Math.cos(y0),
      newY = y0 + y1,
      newX = x0 + x1
    return [newY, newX]
  },

  // '', null, undefined, {}, []
  isEmpty(obj) {
    if (typeof obj == 'number') return false
    else if (typeof obj == 'string') return obj.length == 0
    else if (Array.isArray(obj)) return obj.length == 0
    else if (typeof obj == 'object') return obj == null || Object.keys(obj).length == 0
    else if (typeof obj == 'boolean') return false
    else return !obj
  },

  getFormattedTimeDifference(startDate, endDate) {
    const diffInMilliseconds = endDate.getTime() - startDate.getTime()
    let hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60))
    let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60))
    let seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000)

    // Add leading zero if values are less than 10
    hours = hours < 10 ? '0' + hours : hours
    minutes = minutes < 10 ? '0' + minutes : minutes
    seconds = seconds < 10 ? '0' + seconds : seconds

    return { hours, minutes, seconds }
  },
  moveUp(items = [], index = 0) {
    if (Array.isArray(items)) {
      const [item] = items.splice(index, 1)
      items.splice(index - 1, 0, item)
    } else {
      const item = items[index]
      const previousItem = items[index - 1]
      items[index] = previousItem
      items[index - 1] = item
    }
  },
  moveDown(items = [], index = 0) {
    if (Array.isArray(items)) {
      const [item] = items.splice(index, 1)
      items.splice(index + 1, 0, item)
    } else {
      const item = items[index]
      const nextItem = items[index + 1]
      items[index] = nextItem
      items[index + 1] = item
    }
  },

  getLocationURL(venue = {}, options = {}) {
    return `/${options.account?.slug || venue.accountId?.slug || venue.account?.slug}/${venue.slug}`
  },

  getVenueURL(venue = {}) {
    return this.getLocationURL(venue)
  }
}
