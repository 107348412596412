import routeHelper from './helper';
import get from 'lodash/get'
import find from 'lodash/find'
import { authStore } from '@stores/auth'
import { StorageService } from '@services'
export default [
  {
    path: 'onboarding/',
    redirect: '/',
    name: 'onboarding',
    component: () => import('@layouts/OnboardingLayout.vue').then((c) => c.default || c),
    children: [
      {
        path: 'trial',
        name: 'onboarding-trial',
        component: () => import('@views/onboarding/step1.vue').then((c) => c.default || c),
        meta: {
          title: 'Onboarding Trial',
          event: () => 'Onboarding Trial',
          metaInfo: {
            title: 'Onboarding Trial'
          },
          requiresAuth: true,
          hideHeader: true,
          hideFooter: true,
        },
      },
      {
        path: 'pricing',
        name: 'onboarding-pricing',
        component: () => import('@views/onboarding/step3.vue').then((c) => c.default || c),
        meta: {
          title: 'Onboarding Pricing',
          event: () => 'Onboarding Pricing',
          metaInfo: {
            title: 'Onboarding Pricing',
            prev: 'onboarding-profile'
          },
          requiresAuth: true,
          hideHeader: true,
          hideFooter: true,
        },
        beforeEnter: (to, from, next) => {
          let authData = authStore()
          const user = authData.user
          const sessionAccountId = StorageService.getSessionItem('accountId')
          const accountSlug = sessionAccountId ? sessionAccountId : ''
          const account = find(user.accounts, { slug: accountSlug }) || {}
          const isSubscribed = get(account, 'plans.subscriptionId')
          if (!account?.isActive) {
            return next('/onboarding/profile')
          } else if (isSubscribed) {
            return next('/search')
          }
          return next()
        }
      },
      {
        path: 'profile',
        name: 'onboarding-profile',
        component: () => import('@views/onboarding/step2.vue').then((c) => c.default || c),
        meta: {
          title: 'Onboarding Profile',
          event: () => 'Onboarding Profile',
          metaInfo: {
            title: 'Onboarding Profile',
            prev: 'onboarding-trial'
          },
          requiresAuth: true,
          hideHeader: true,
          hideFooter: true,
        },
      }
    ]
  }
]
