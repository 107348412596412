import { defineStore } from 'pinia'
import { PermissionService } from '@services'

export const permissionStore = defineStore('permissionStore', {
  state: () => ({
    permissionList: null,
    accountPermission: null
  }),
  persist: true,
  actions: {
    async getPermissionList() {
      const response = await PermissionService.getAll()
      if (response) {
        this.permissionList = response.data
      }
    },
    async getAccountPermission() {
      const response = await PermissionService.get()
      if (response) {
        this.accountPermission = response.data
      }
    }
  }
})
