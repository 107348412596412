import Compressor from 'compressorjs'
export default {
  methods: {
    $userHasImage(user) {
      return user && user.media && user.media[0]
    },

    $getUserImage(user = {}, size = 'original') {
      if (this.$userHasImage(user)) {
        if (user.media[0].tempURL) {
          return user.media[0].tempURL
        } else if (user.media[0].facebook) {
          return `https://graph.facebook.com/${user.media[0].facebook}/picture?type=large&height=${size}&width=${size}`
        } else if (user.media[0].original) {
          return this.$getImage(user.media[0].original, size)
        }
      }

      return `${this.assetURL}/user.png`
    },

    $venueHasImage(space, index) {
      return space && space.media && space.media[index || 0]
    },

    $getVenueImage(venue = {}, size = '1600', index = 0) {
      if (this.$venueHasImage(venue, index)) {
        if (venue.media[index].tempURL) {
          return venue.media[index].tempURL
        } else if (venue.media[index].url) {
          return this.$getImage(venue.media[index].url, size)
        }
      }
      if (venue.tempURL) {
        return venue.tempURL
      } else if (venue.url || venue.media?.url) {
        const url = venue.media?.url ? venue.media?.url : venue.url
        return this.$getImage(url, size)
      }
      return `${this.assetURL}/placeholder.png`
    },

    $getImage(src, size = '1600') {
      let a = (src || '').replace('/original', `/${size}`)
      a = a.replace('%2Foriginal', `/${size}`)
      if(import.meta.env.VITE_APP_NODE_ENV==='production'){
        return a.replace(import.meta.env.VITE_APP_S3_URL, import.meta.env.VITE_APP_CLOUD_FRONT_URL)
      }
      return a
    },

    $getContentImage(src, size = '1600') {
      if (src.includes(this.imageURL)) {
        let a = (src || '').replace('/original', `/${size}`)
        if(import.meta.env.VITE_APP_NODE_ENV==='production'){
          return a.replace(import.meta.env.VITE_APP_S3_URL, import.meta.env.VITE_APP_CLOUD_FRONT_URL)
        }
        return a
      }
      return src
    },
    $compressFile(file) {
      return new Promise(function (resolve, reject) {
        new Compressor(file, {
          quality: 0.5,
          success(result) {
            resolve(result)
          },
          error(err) {
            reject(error)
            console.log(err.message)
          }
        })
      })
    },

    findNextRank(currentRank){
      const currentIndex = this.mediaList?.findIndex(item=> item.rank == currentRank);
      return this.mediaList[currentIndex + 1]?.rank || currentRank
    },

    findPrevRank(currentRank){
      const currentIndex = this.mediaList?.findIndex(item=> item.rank == currentRank);
      return  this.mediaList[currentIndex - 1]?.rank || currentRank
    },

    getName(item){
      let displayName  = item?.title || item?.name
      if(this.$route?.name === 'collection.share.edit'){
        if(item?.meta?.locationId){
          displayName = item?.meta?.locationId?.name
        }
        if(item?.meta?.collectionId){
          displayName = item?.meta?.collectionId?.name
        }
      }
      return displayName;
    }
  }
}
